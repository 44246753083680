export class Api {
  constructor(result) {
    this.result = result
    this.prev = null
    this.stopped = false
  }

  async subscribe (workStationId) {
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (this.stopped) {
      return
    }

    try {
      let response = await fetch(`${localStorage.getItem('backendHost') || ''}/v1/get/${workStationId}`);

      if (response.status !== 200) {
        await this.subscribe(workStationId);
      }

      let res = await response.json();

      this.result({
        code: res.code,
        error: false,
        prevAnimation: this.prev?.promo?.animation,
        data: this.prev = res.data,
        random: Math.random(),
      });

      await this.subscribe(workStationId);
    } catch (e) {
      console.log(`Polling error: ${e}`);
      this.result({
        code: 0,
        error: true,
        prevAnimation: this.prev?.promo?.animation,
        data: this.prev
      });

      await this.subscribe(workStationId);
    }
  }

  start () {
    console.log('Polling started ...');

    this.stopped = false;
  }

  stop () {
    console.log('Polling stopped ...');

    this.stopped = true;
  }
}
