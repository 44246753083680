<template>
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</template>

<script setup>


</script>

<style scoped lang="sass">
.lds-ring
  display: inline-block
  width: 80px
  height: 80px
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  div
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border: 8px solid transparent
    border-top-color: #fff

    &:nth-child(1)
      animation-delay: -0.45s
    &:nth-child(2)
      animation-delay: -0.3s
    &:nth-child(3)
      animation-delay: -0.15s
  @keyframes lds-ring
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(360deg)
</style>
