<template>
  <div class="error__message">Ошибка получения данных от сервера.</div>
</template>

<script setup>

</script>

<style scoped lang="sass">
.error__message
  position: absolute
  bottom: 0
  width: 100%
  padding: 30px
  border: 4px solid red
  text-align: center
  color: red
  font-size: 24px
  background: black
  z-index: 1000
</style>
