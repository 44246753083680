<template>
<span>{{ time }}</span>
</template>

<script setup>
import { ref, defineProps, onBeforeUnmount, defineEmits } from 'vue'

const props = defineProps ({
  startTime: {
    type: Number,
    default: 60
  }
})
const emit = defineEmits(['done'])


const timer = setInterval(countdown, 1000);
const time = ref(props.startTime)
function countdown () {
  time.value--
  if (time.value === 0) {
    clearInterval(timer)
    emit('done')
  }
}

onBeforeUnmount(() => {
  clearInterval(timer)
})
</script>

<style scoped>

</style>
