<template>
  <div class="bg">
    <base-loader
      v-if="res === null"
    />
    <company-logo
      v-if="res !== null && (res.code !== 0 || sortedImages.length < 1)"
    />

    <div
      class="modal__bg"
      :class="{ active: res !== null && get(props.res, 'data.check.sbp', '') !== '' }"
    >
      <div
        class="qrcode__wrapper" ref="checkQrcode" v-if="res !== null && get(props.res, 'data.check.sbp', '') !== ''"
      >
        <div class="help-text" :style="{'max-width': qrCodePaymentHeight + 'px'}">Откройте приложение вашего банка и отсканируйте QR-код</div>
        <QRCodeVue3
          class="qrcode"
          :width="qrCodePaymentHeight"
          :height="qrCodePaymentHeight"
          :value="get(props.res, 'data.check.sbp')"
          fileExt="svg"
          image="sbp.svg"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 4 }"
          :dotsOptions="{
            type: 'eclassy-rounded',
            color: '#000',
          }"
          :cornersSquareOptions="{ type: 'square', color: '#000000' }"
        />
      </div>
    </div>

    <div
      class="bg__white"
      ref="checkTable"
      v-if="res !== null && get(props.res, 'data.check.products', []) !== null && get(props.res, 'data.check.products', []).length > 0"
    >
      <div class="th-wrapper">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th class="number">№</th>
          <th>Товар</th>
          <th>Кол-во</th>
          <th class="text__right">Цена</th>
          <th class="text__right">Итого</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, idx) in get(res, 'data.check.products', [])" :key="idx">
          <td class="number">{{ item.number }}</td>
          <td class="cell">{{ item.name }}</td>
          <td class="cell">{{ numberFormat(item.quantity) }}&nbsp;{{ item.unit}}</td>
          <td class="cell text__right">{{ numberFormat(item.price, true) }}</td>
          <td class="cell text__right">{{ numberFormat(item.amount, true) }}</td>
        </tr>
        </tbody>
        <tfoot>
          <tr v-if="get(res,'data.check.discount', null) !== null">
            <td colspan="3" class="table__title table__title-right">
              <span class="weight700">Скидка:</span>
            </td>
            <td colspan="2" class="table__title-right cell">
              {{ numberFormat(res.data.check.discount, true) }}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="table__title cell">
              <span v-if="get(res,'data.check.award', null) !== null" class="weight700">Начислено: </span>
              <span v-if="get(res,'data.check.award', null) !== null">{{ numberFormat(res.data.check.award) + ' ' + declPoints(numberFormat(res.data.check.award), ['балл', 'балла', 'баллов']) }}</span>
            </td>
            <td class="table__title table__title-right">
              <span class="total weight700" v-if="get(res,'data.check.total', null) !== null">Итого:</span>
            </td>
            <td colspan="2" class="table__title-right weight700 cell">
              <span v-if="get(res,'data.check.total', null) !== null">{{ numberFormat(res.data.check.total, true) }}</span>
            </td>
          </tr>

          <tr>
            <td colspan="2" class="table__title cell">
              <div v-if="get(res,'data.check.redemption', null) !== null">
                <span class="weight700">Списано: </span>
                <span>{{ numberFormat(res.data.check.redemption) + ' ' + declPoints(numberFormat(res.data.check.redemption), ['балл', 'балла', 'баллов']) }} </span>
              </div>
            </td>
            <td class="table__title table__title-right">
              <span class="m-left weight700" v-if="get(res,'data.check.deposit', null) !== null">Внесено&nbsp;/&nbsp;Сдача:</span>
            </td>
            <td colspan="2" class="table__title-right cell">
              <span v-if="get(res,'data.check.deposit', null) !== null">
                  {{ numberFormat(res.data.check.deposit, true) }}&nbsp;/&nbsp;{{ numberFormat(res.data.check.change, true) }}
              </span>
            </td>
          </tr>

          <tr v-for="(item, index) in res.data.check.payments" :key="index">
            <td colspan="3" class="table__title table__title-right weight700"><span>{{ item.name }}:</span></td>
            <td colspan="2" class="table__title-right"> {{  numberFormat(item.amount, true) }}</td>
          </tr>

          <tr v-if="res !== null && get(props.res, 'data.check.fiscal', '') !== ''">
            <td colspan="3" class="table__title table__title-right weight700">
              Фискальный чек:
            </td>
            <td colspan="2" class="table__title-right">
              <QRCodeVue3
                class="qrcode"
                :width="qrCodeFiscalHeight"
                :height="qrCodeFiscalHeight"
                :value="get(props.res, 'data.check.fiscal')"
                :dotsOptions="{
                type: 'classy',
                color: '#000',
              }"
                :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              />
            </td>
          </tr>

        </tfoot>
      </table>
    </div>

    <swiper
      ref="slider"
      :style="{ height: `calc(100vh - ${calculateHeight()}px)`}"
      class="mySwiper"
      v-if="res !== null && res.code === 0 && renderComponent && sortedImages.length > 0"
      :effect="get(props.res, 'data.promo.animation')"
      :fadeEffect="{ crossFade: true }"
      :creativeEffect="{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }"
      :modules="swiperModules"
      :loop="true"
      :calculateHeight="true"
      :autoplay="{
        disableOnInteraction: false,
      }"
    >
      <swiper-slide
        v-for="(item, index) in sortedImages"
        :key="index"
        :data-swiper-autoplay="1000 * item.time"
        :style="{ height: `calc(100vh - ${calculateHeight()}px)`}"
      >
        <video
          v-if="item.url.includes('.mp4')"
          autoplay
          muted
          loop
          :style="{ height: `calc(100vh - ${calculateHeight()}px)`}"
        >
          <source :src="`${backendHost}${item.url}`" type="video/mp4">

        </video>
        <img v-else :src="`${backendHost}${item.url}`" alt=""  :style="{ height: `calc(100vh - ${calculateHeight()}px)`}">
      </swiper-slide>
    </swiper>
  </div>
  <error-message v-if="res !== null && res.error"/>
  <button type="button" class="transparent" @click="$emit('clickedTransparentBtn')"></button>
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectCards, EffectCreative, EffectFade, EffectFlip, } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-creative';
import BaseLoader from '@/components/BaseLoader';
import CompanyLogo from '@/components/CompanyLogo';
import ErrorMessage from '@/components/ErrorMessage';
import get from 'lodash.get';
import QRCodeVue3 from "qrcode-vue3";


defineEmits(['clickedTransparentBtn'])


const props = defineProps({
  res: {
    type: Object,
    default: () => {}
  }
})

onMounted( () =>  {
  window.addEventListener("resize", calculateHeight);
})

onUnmounted(() => {
  window.removeEventListener("resize", calculateHeight);
})

setInterval(() => {
  window.location.reload()
}, 32400000)


setInterval(async () => {
  const prev = get(props.res, 'prevAnimation');
  const curr = get(props.res, 'data.promo.animation');

  if (prev === undefined || prev === null) {
    return
  }

  if (curr === undefined || curr === null) {
    return
  }

  if (prev !== curr) {
    console.log(`Animation, prev: ${prev}, curr: ${curr}`);
    console.log('force re-render swiper');

    await forceRerender()
  }
}, 2000)

const forceRerender = async () => {
  // Remove MyComponent from the DOM
  renderComponent.value = false;

  // Wait for the change to get flushed to the DOM
  await nextTick();

  // Add the component back in
  renderComponent.value = true;
};

const qrCodePaymentHeight = 400
const qrCodeFiscalHeight = 200
const backendHost = ref(localStorage.getItem('backendHost') || '')
const swiperModules = ref([Autoplay, EffectFade,EffectCards, EffectFlip, EffectCreative,])
const checkTable = ref(null)
const checkQrcode = ref(null)
const checkFiscal = ref(null)
const slider = ref(null)
const renderComponent = ref(true)
const sortedImages = computed(() => {
  const images = get(props.res, 'data.promo.images', []);
  const now = new Date();
  const currentDay = now.toLocaleString('en-US', { weekday: 'short' }).toLowerCase();
  const currentTime = now.toTimeString().split(' ')[0];
  return images
    .filter(image => {
      const startDate = new Date(image.start.split('T')[0]);
      const stopDate = new Date(image.stop.split('T')[0]);
      const days = JSON.parse(image.days);

      // Проверка по дате
      const isInDateRange = now >= startDate && now <= stopDate;

      // Проверка по дню недели
      const isDayValid = days.includes(currentDay);

      // Проверка по времени
      const isTimeValid = currentTime >= image.begin && currentTime <= image.end;

      return isInDateRange && isDayValid && isTimeValid;
    })
    .sort((a, b) => a.order - b.order)
});

function calculateHeight () {

  if (checkTable.value !== null) {
    return checkTable.value.clientHeight;
  }

  return 0;
}

function numberFormat (number, sign) {
  if (sign) {
    return new Intl.NumberFormat('ru-RU', { style: "currency", currency: "RUB" }).format(number)
  }

  return new Intl.NumberFormat('ru-RU').format(number)
}

function declPoints(number, titles) {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

</script>

<style scoped lang="sass">
.cell
  padding: 0.8vw
.number
  text-align: center !important
.th-wrapper
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 4vh
  display: flex
  z-index: 1
  gap: 1vw
  .left
    flex: 0 0 35%
    background-color: #004798
    transform: skew(-14deg)
    margin-left: -8vw
  .right
    flex: 0 0 71%
    background-color: #E7332B
    transform: skew(-14deg)

.modal__bg
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  background: rgba(0, 0, 0, 0.7)
  opacity: 0
  pointer-events: none
  transition: 0.5s all
  &.active
    opacity: 1
    pointer-events: all
    transition: 0.5s all
    z-index: 1000
.m-left
  margin-left: -11vw
.help-text
  max-width: 200px
  font-size: 18px
  margin-bottom: 14px
  padding-left: 10px
.qrcodeFiscal__wrapper
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background: white
  padding: 16px 24px
  border-radius: 4px
  h2
    margin-bottom: 40px
.qrcode
  &__wrapper
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: white
    padding: 16px 24px
    border-radius: 4px
.bg
  background: black
  height: 100vh
.wrapper
  width: 100vw
  height: 100vh
  overflow: hidden
.transparent
  background: transparent
  border: none
  position: absolute
  top: 0
  right: 0
  z-index: 1000
  width: 36vw
  height: 36vh
  cursor: pointer
  &:hover
    background: rgba(255, 255, 255, 0.1)

.swiper-slide
  display: flex
  justify-content: center
  align-items: center
  img
    object-fit: contain
.bg__white
  background: #fff
.table
  font-family: 'Montserrat Medium', sans-serif
  table-layout: fixed
  border-collapse: collapse
  background: white
  width: 100%
  th:nth-child(1),
  td:nth-child(1)
    width: 4vw
  th:nth-child(2),
  td:nth-child(2)
    width: auto
  th:nth-child(3),
  td:nth-child(3)
    width: 11.5vw
  th:nth-child(4),
  td:nth-child(4)
    width: 15.7vw
  th:nth-child(5),
  td:nth-child(5)
    width: 15.6vw
  th,
  td
    text-align: left
  th
    font-size: 2.4vw
    vertical-align: middle
    padding: 0 0.8vw
  th:last-child
    padding: 0.4vh 2vw 0.4vh 1vw
  td:last-child
    padding: 1vh 2vw 1vh 1vw
  tr:first-child
    td
      border-top: none

  td
    border: 1px solid #ddd
    font-size: 2.2vw
  tr:nth-child(even)
    background-color: #f2f2f2
  th
    height: 4vh
    color: white
    white-space: nowrap
    position: relative
    z-index: 2
  tfoot
    td
      border: 1px solid transparent
      font-size: 2.4vw
    .table__title
      &-right
        text-align: right
.total
  text-transform: uppercase
  font-size: 2.6vw
.text__right
  text-align: right !important
.weight700
  font-weight: 700
</style>
