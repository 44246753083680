<template>
  <form class="numpad">
    <input :class="{'numpad__item-a': true, 'saved': saved }" v-model="workplaceNumber" readonly>

    <button
      type="button"
      v-for="(item, index) in numbers"
      :key="index"
      class="number"
      :class="'numpad__item-' + item.key"
      @click="getValue(item.number)"
    >
      {{ item.number }}
    </button>

    <button type="button" class="numpad__item-l" @click="clearWorkplaceNumber()">Очистить</button>
    <button type="button" class="numpad__item-m" @click="saveWorkplaceNumber()">Сохранить</button>
  </form>
</template>

<script setup>
import { onMounted, ref, defineEmits } from 'vue';

const workplaceNumber = ref('')
const numbers = ref([
  {key: 'b', number: 7},
  {key: 'c', number: 8},
  {key: 'd', number: 9},
  {key: 'e', number: 4},
  {key: 'f', number: 5},
  {key: 'g', number: 6},
  {key: 'h', number: 0},
  {key: 'i', number: 1},
  {key: 'j', number: 2},
  {key: 'k', number: 3},
]);

let array = []
const saved = ref(false)
const emit = defineEmits(['reset', 'saved'])

function getValue (value) {
  array.push(value)

  if (array.length > 2) {
    array = []
    array.push(value)
  }

  if (array[0] === 0) {
    array.shift()
  }

  workplaceNumber.value = array.join('')

  saved.value = workplaceNumber.value === localStorage.getItem('workplaceNumber');
}

function clearWorkplaceNumber () {
  array = []
  workplaceNumber.value = ''
  localStorage.removeItem('workplaceNumber')
  saved.value = false
  emit('reset')
}

function saveWorkplaceNumber () {
  if (workplaceNumber.value === '') {
    alert('Введите номер рабочего места')
    return
  }
  array = []
  localStorage.setItem('workplaceNumber', workplaceNumber.value)
  saved.value = true
  emit('saved')
}

onMounted(() => {
  const currentWorkplaceNumber = localStorage.getItem('workplaceNumber')

  if (currentWorkplaceNumber) {
    workplaceNumber.value = currentWorkplaceNumber
    saved.value = true
  }

})

</script>

<style scoped lang="sass">
.saved
  background: #42b983 !important
.numpad
  margin-bottom: 20px
  border: 1px solid #808080
  padding: 10px
  display: grid
  gap: 10px
  grid-template-columns: 60px 60px 60px 60px 60px
  grid-template-rows: auto
  grid-template-areas: "input input seven eight nine" "input input for five six" "zero zero one two three" "btn-clear btn-clear btn-save btn-save btn-save"
  &__item-a
    grid-area: input
    background: #FFBFBF
    text-align: center
    cursor: auto
    font-size: 70px
    &:focus
      outline: none
  &__item-b
    grid-area: seven
  &__item-c
    grid-area: eight
  &__item-d
    grid-area: nine
  &__item-e
    grid-area: for
  &__item-f
    grid-area: five
  &__item-g
    grid-area: six
  &__item-h
    grid-area: zero
  &__item-i
    grid-area: one
  &__item-j
    grid-area: two
  &__item-k
    grid-area: three
  &__item-l
    grid-area: btn-clear
    background: #FF8080
  &__item-m
    grid-area: btn-save
    background: #81FF88
  &__item-l,
  &__item-m
    height: 61px
    font-size: 20px
    font-weight: 700
    cursor: pointer
  button,
  input
    border: 1px solid #808080
.number
  font-size: 16px
  font-weight: 700
  background: #FFFFC2
  height: 61px
  cursor: pointer

</style>
