<template>
  <InitialConfiguration
    @configured="startPolling"
    v-if="currentScreen === SCREEN_INITIAL_CONFIGURATION"
  />
  <AdvScreen
    :res="result"
    v-if="currentScreen === SCREEN_ADV"
    @clickedTransparentBtn="clickedTransparentBtn()"
  />
  <PaymentScreens
    v-if="currentScreen === SCREEN_PAYMENT"
  />
</template>

<script setup>
import InitialConfiguration from './screens/InitialConfiguration.vue';
import AdvScreen from './screens/AdvScreen.vue';
import PaymentScreens from './screens/PaymentScreens.vue';
import { Api } from '@/api.js'
import { onMounted, reactive, ref } from 'vue';

const SCREEN_INITIAL_CONFIGURATION = 'initial_configuration';
const SCREEN_ADV = 'adv';
const SCREEN_PAYMENT = 'payment';

const currentScreen = ref(SCREEN_INITIAL_CONFIGURATION)

let result = ref(null);
const api = new Api((data) => {
  result.value = data;
})

onMounted(async () => {
  const number = localStorage.getItem('workplaceNumber')
  const workingMode = localStorage.getItem('workingMode')

  if (number && workingMode === 'enabled') {
    currentScreen.value = SCREEN_ADV
    await startPolling()
  }
})

async function startPolling () {
  currentScreen.value = SCREEN_ADV
  const number = localStorage.getItem('workplaceNumber')

  api.start();
  await api.subscribe(number)
}

function clickedTransparentBtn () {
  api.stop();
  currentScreen.value = SCREEN_INITIAL_CONFIGURATION
}

</script>

<style lang="sass">
*
  box-sizing: border-box
  margin: 0
  padding: 0
body
  overflow: hidden
#app
  font-family: 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #000
</style>
