<template>
<div class="initial-configuration">
  <h1>Приложение требует настройки со стороны администратора</h1>
  <div class="initial__container">
    <div class="initial__flex-container">
      <div class="initial__subtitle">Введите номер рабочего места</div>
      <a @click="settings = true">Настроить</a>
    </div>
    <div v-if="!settings">
      <NumPad @reset="showAdvConfirmation = false" @saved="showAdvConfirmation = true"/>
      <AdvConfirmation v-if="showAdvConfirmation" @confirmed="switchToWorkingMode()"/>
    </div>

    <div v-if="settings">
      <label>Хост бекенда</label>
      <div class="settings__input">
        <input type="text"  v-model="host" @input="saveHost()">
      </div>
      <label>Ссылка на логотип компании</label>
      <div class="settings__input">
        <input type="text" v-model="link" @input="saveLink()">
      </div>
      <div class="initial__flex-container">
        <button class="settings__button back" @click="settings = false">Назад</button>
        <button class="settings__button clear" @click="removeElementsFromLocalStorage()">Очистить кэш</button>
      </div>
    </div>

  </div>
</div>
</template>

<script setup>
import NumPad from '../components/NumPad.vue';
import AdvConfirmation from '../components/AdvConfirmation.vue';
import { defineEmits, onMounted, ref } from 'vue';

const emit = defineEmits(['configured'])
let showAdvConfirmation = ref(false)
const settings = ref(false)
const host = ref(localStorage.getItem('backendHost') || '')
const link = ref(localStorage.getItem('companyLogoLink'))

onMounted(() => {
  if (localStorage.getItem('workplaceNumber')) {
    showAdvConfirmation.value = true
  }
})

function switchToWorkingMode () {
  localStorage.setItem('workingMode', 'enabled')
  emit('configured')
}

function removeElementsFromLocalStorage () {
  localStorage.removeItem('workplaceNumber')
  localStorage.removeItem('workingMode')
  localStorage.removeItem('backendHost')
  localStorage.removeItem('companyLogoLink')
  location.reload()
}

function saveHost () {
  localStorage.setItem('backendHost', host.value)
}

function saveLink () {
  localStorage.setItem('companyLogoLink', link.value)
}
</script>

<style scoped lang="sass">
.initial-configuration
  h1
    text-align: center
    margin: 50px 0 40px 0
  a
    text-decoration: underline
    cursor: pointer
    font-size: 14px
.initial
  &__subtitle
    font-size: 18px
  &__container
    max-width: 362px
    margin: 0 auto
  &__flex-container
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 10px
    gap: 10px
.settings
  &__button
    width: 100%
    height: 61px
    font-size: 20px
    font-weight: 700
    cursor: pointer
    &.back
      background: #FFFFC2
    &.clear
      background: #FF8080
  &__input
    margin-bottom: 20px
    input
      width: 100%
      height: 40px
      padding: 20px

</style>
